import React from "react";
export default function MetaHoverCard({ title, paragraph, metaHovercard }) {
  return (
    <section className="section_space">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-xl-5 mb-lg-4 mb-sm-3">
            <h5 className="sami_medium_heading">{title}</h5>
            <p>{paragraph}</p>
          </div>

          {metaHovercard.map((el) => (
            <div className="col-lg-3 col-md-6">
              <div className="border-image hover_gradgent">
                <span className="font_16 fw-bold mb-xl-3 mb-2 d-block">{el.sub_title}</span>
                <h3 className="sub_small_heading  mb-2">{el.heading}</h3>
                <p className="font_15 mb-0">{el.paragraph}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
