import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import MetatradeHero from "../component/MetatradeHero";
import LeftSection from "../component/LeftSection";
import MetaCard from "../component/MetaCard";
import RightSection from "../component/RightSection";
import TradeMt5 from "../component/TradeMt5";
import MetaHoverCard from "../component/MetaHoverCard";
import MtFaq from "../component/MtFaq";
import ScrollToTop from "../component/ScrollToTop";

export default function Metatrader5() {
  const cardItem = [
    {
      img: "/assets/images/mt4/code.svg",
      heading: "MetaEditor",
      paragraph:
        " On MetaTrader 5, you can develop trading robots and technical indicators through the specialized MetaEditor tool. As the tool is linked with the platform, new programs will automatically appear in your MetaTrader 5 and can be executed instantly.",
    },
    {
      img: "/assets/images/mt4/arrows_sort.svg",
      heading: "Hedging system",
      paragraph:
        "In MetaTrader 5 with Tradsify, you can experience trading using the hedging mode system. Hedging allows you to open multiple positions, even exact opposite positions, for a trading instrument.",
    },
    {
      img: "/assets/images/mt4/calendar_dollar.svg",
      heading: "Fundamental analysis",
      paragraph:
        "Capture market opportunities with fundamental analysis tools on the MetaTrader 5, such as the built-in Economic Calendar. Keep abreast of the latest news events, expected market impacts and forecasts.",
    },
    {
      img: "/assets/images/mt4/graph.svg",
      heading: "Indicators & analytical object tools",
      paragraph:
        "Enhance your trading experience with 38 built-in indicators, 22 analytical tools and 46 graphical objects when you trade financial instruments in the trading platform.",
    },
  ];

  const tradecard = [
    {
      heading: "Forex",
      paragraph: "There are more than 100 currency pairs available for CFD trading on MT5 in Tradsify. We offer major currency pairs, including EURUSD, GBPUSD and USDJPY, and minor currency pairs. There is also a long list of exotic pairs available for you to trade CFDs on.",
    },
    {
      heading: "Metals",
      paragraph: "On MT5 with Tradsify, you can trade CFDs on metals in the form of currency pairs, which include XAUUSD, XAUEUR, XAUGBP and XAUAUD for gold and XAGUSD, XAGEUR, XAGGBP and XAGAUD for silver. You can also trade on platinum (XPT) and palladium (XPD) in currency pairs.",
    },
    {
      heading: "Energies",
      paragraph: "Hedge your portfolio on MT5 with Tradsify and trade CFDs on popular energies like brent crude oil (UKOIL), crude oil (USOIL) and natural gas (XNGUSD) with better-than-market conditions.",
    },
    {
      heading: "Stocks",
      paragraph: "Gain exposure to a large selection of stock CFDs while trading on MT5 with Tradsify. Trade CFDs on stocks from different industries, such as technology (APPL, META), consumer discretionary (TSLA), consumer staples (KO) and others.",
    },
    {
      heading: "Indices",
      paragraph: "Diversify your portfolio and trade CFDs on major stock indices from the US, UK, Germany, Japan, and China on MT5 with Tradsify. Access popular global indices like the Dow Jones, NASDAQ, FTSE 100, and NIKKEI 225.",
    },
    // {
    //   heading: "currencies",
    //   paragraph:
    //     "You can trade the most popular currency pairs on MetaTrader 5. These include CFDs on Bitcoin, Ethereum and Litecoin, with Bitcoin available in BTCUSD, BTCKRW, BTCJPY and more.",
    // },
  ];
  const faq = [
    {
      question: "1. Can you trade CFD on MetaTrader 5 (MT5) platform?",
      answer:
        "Yes, you can trade Contracts for Difference (CFtradsify5 from your home country, whether you are from India or other regions of the world. Tradsify offers CFD trading for forex, indices, shares, commodities, ETFs and bonds. You can trade these CFDs on both MetaTrader 4 (MT4) and MetaTrader 5 (MT5) trading platforms.",
    },
    {
      question: "2. What can you trade on MetaTrader 5 (MT5)?",
      answer:
        "You can trade CFDs for forex, indices, shares, commodities, ETFs and bonds on MetaTrader 5. This means access to highly traded forex pairs, leading indices, top companies listed on stock exchanges, and more.",
    },
    {
      question: "3. Is MetaTrader 5 (MT5) suitable for beginners?",
      answer:
        "MetaTrader 5 can be a suitable trading platform for all types of traders, from beginner to expert. As a beginner, MT5 allows you to gain access to a variety of tools for your fundamental and technical analysis. With the latest version of MT5, you can also tap on the support of forex robots, trading signals and the capability to hedge positions. The MetaTrader 5 (MT5) platform can be accessed anywhere, anytime, and is available on both mobile apps and web browsers.",
    },
    {
      question: "4. Can you use MetaTrader 5 (MT5) for stocks?",
      answer:
        "Yes, you can use MetaTrader 5 to trade stocks via share CFDs on Tradsify. Stocks that you can trade include Apple (AAPL), Tesla (TSLA), Microsoft (MSFT), Netflix (NFLX), Alphabet (GOOG) and more.",
    },
  ];
  const metaHovercard = [
    {
      sub_title: "1 STEP",
      heading: "Download MT5",
      paragraph:
        "Open a live account or demo account to get the Tradsify MetaTrader 5 (MT5) software.",
    },
    {
      sub_title: "2 STEP",
      heading: "Run Setup",
      paragraph: "Open the Tradsifysetup.exe file and select “Run”.",
    },
    {
      sub_title: "3 STEP",
      heading: "Complete Installation",
      paragraph:
        "Allow changes, agree to the Licence Agreement, and choose settings if needed.",
    },
    {
      sub_title: "4 STEP",
      heading: "Finish MT5 Installation",
      paragraph:
        "Click `Next` to proceed through the Tradsify MT5 Setup. The installer will download the latest MT5 version. Once done, click `Finish`.",
    },
  ];

  return (
    <>
      <ScrollToTop>
        <Header />

        <MetatradeHero
          title="MetaTrader 5 (MT5)"
          paragraph=" Trade CFDs on your favourite trading instruments using the MetaTrader 5. A powerful platform for currency pairs and other financial"
          paragraph2="instruments CFD trading, the MetaTrader 5 is free to download on Tradsify."
          btn="Download MetaTrader 5"
        />
        <LeftSection
          img="/assets/images/mt4/mt4.webp"
          main_title="A powerful multi-asset platform"
          para1="The fifth generation of the MetaTrader platforms, MetaTrader 5 offers increased functionalities and features from its predecessor and quickly"
          para2="became one of the most popular trading platforms by online foreign exchange traders and brokerage services around the world."
          heading="Usage of MetaTrader 5"
          metapara1=" Fundamental and technical analysis, trading signals, algorithmic
                trading - the trading platform provides an arsenal of tools that
                enhance the trading experience. The platform also has broadcasts
                of the latest financial news reports for traders to keep
                up-to-date on the markets."
          metapara2="With the trading signals and  capability in
                Metradsify5, traders can follow the signals of successful
                traders and copy their trading strategies and orders, which will
                be automatically reproduced on your account."
        />
        <MetaCard cardItem={cardItem} />
        <RightSection
          heading="Mobile trading and MetaTrader 5"
          para1=" The trading terminal is not just for Windows, macOS and Linux powered desktops. Traders with busy schedules can also perform mobile trading on their iOS and Android smartphones and tablets. Equipped with all the integral trading tools, including trading orders, interactive charts and popular analytical tools, you can monitor your account and perform mobile trading in a single click."
          para2=" The trading terminal is not just for Windows, macOS and Linux
                powered desktops. Traders with busy schedules can also perform
                mobile trading on their iOS and Android smartphones and tablets.
                Equipped with all the intradsifyading tools, including trading
                orders, interactive charts and popular analytical tools, you can
                monitor your account and perform mobile trading in a single
                click."
          btn="Downloade MT5"
          img="/assets/images/mt4/mt5.webp"
        />
        <TradeMt5
          title="What you can trade on MT5 "
          para1="At Tradsify, you can enjoy trading CFDs on more than 200
                instruments, which include trading "
          para2="forex currency pairs, metals, currencies, stocks, indices
                and energies."
          tradecard={tradecard}
        />
        <MetaHoverCard
          title="MetaTrader 5 (MT5) Download"
          paragraph=" Discover a superior trading platform in MetaTrader 5 (MT5) now."
          metaHovercard={metaHovercard}
        />
        <MtFaq title="Frequently asked questions" faq={faq} />
        <Footer />
      </ScrollToTop>
    </>
  );
}
