import React from "react";
export default function LeftSection({
  main_title,
  para1,
  para2,
  heading,
  metapara1,
  metapara2,
  img,
}) {
  return (
    <>
      <section className="section_space">
        <div className="container">
          <h2 className="sub_heading text-center">{main_title}</h2>
          <p className="text-center font_16">
            {para1}
            <br className="d-lg-block d-none " />
            {para2}
          </p>
          <div className="row g-4 mt-lg-5 mt-sm-3 align-items-center">
            <div className="col-lg-5 col-12">
              <img src={img} alt="" className="img-fluid w-100" />
            </div>
            <div className="col-lg-6 col-12 offset-lg-1">
              <h5 className="sami_medium_heading">{heading}</h5>
              <p className="font_16">{metapara1}</p>
              <p className="font_16">{metapara2}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
