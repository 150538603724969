import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";

export default function Service() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space about_section mt5_bg">
                    <div className="container">
                        <h1 className="main_heading text-light">
                            MetaTrader 5 (MT5)
                        </h1>
                        <p className="font_15 mb-5">
                            Trade CFDs on your favourite trading instruments using the MetaTrader 5. A powerful platform for currency pairs and other financial <br />instruments CFD trading, the MetaTrader 5 is free to download on tradsify.
                        </p>
                        <button className="btn btn-light">Download MetaTrader 5</button>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <h2 className="sami_medium_heading text-center ">
                            A powerful multi-asset platform
                        </h2>
                        <p className="text-center">
                            The fifth generation of the MetaTrader platforms, MetaTrader 5 offers increased functionalities and features from its predecessor and quickly
                            <br className="d-lg-block d-none" />
                            became one of the most popular trading platforms by online foreign exchange traders and brokerage services around the world.
                        </p>
                        <div className="row g-4 mt-5 align-items-center">
                            <div className="col-lg-5 col-12">
                                <img src="/assets/images/mt4/mt4.webp" alt="" className="img-fluid" />

                            </div>
                            <div className="col-lg-6 col-12 offset-lg-1">
                                <h5 className="sami_medium_heading">
                                    Usage of MetaTrader 5
                                </h5>
                                <p>
                                    Fundamental and technical analysis, trading signals, algorithmic trading - the trading platform provides an arsenal of tools that enhance the trading experience. The platform also has broadcasts of the latest financial news reports for traders to keep up-to-date on the markets.
                                </p>
                                <p>
                                    With the trading signals and capability in MetaTrader 5, traders can follow the signals of successful traders and  their trading strategies and orders, which will be automatically reproduced on your account.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-lg-6">
                                <div className="why_section_card">
                                    <img src="/assets/images/mt4/code.svg" alt="" className="img-fluid mb-5" />
                                    <h5 className="small_heading">
                                        MetaEditor
                                    </h5>
                                    <p className="mb-0 font_15">
                                        On MetaTrader 5, you can develop trading robots and technical indicators through the specialized MetaEditor tool. As the tool is linked with the platform, new programs will automatically appear in your MetaTrader 5 and can be executed instantly.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why_section_card">
                                    <img src="/assets/images/mt4/arrows_sort.svg" alt="" className="img-fluid mb-5" />
                                    <h5 className="small_heading">
                                        Hedging system
                                    </h5>
                                    <p className="mb-0 font_15">
                                        In MetaTrader 5 with tradsify, you can experience trading using the hedging mode system. Hedging allows you to open multiple positions, even exact opposite positions, for a trading instrument.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why_section_card">
                                    <img src="/assets/images/mt4/calendar_dollar.svg" alt="" className="img-fluid mb-5" />
                                    <h5 className="small_heading">
                                        Fundamental analysis
                                    </h5>
                                    <p className="mb-0 font_15">
                                        Capture market opportunities with fundamental analysis tools on the MetaTrader 5, such as the built-in Economic Calendar. Keep abreast of the latest news events, expected market impacts and forecasts.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why_section_card">
                                    <img src="/assets/images/mt4/graph.svg" alt="" className="img-fluid mb-5" />
                                    <h5 className="small_heading">
                                        Indicators & analytical object tools
                                    </h5>
                                    <p className="mb-0 font_15">
                                        Enhance your trading experience with 38 built-in indicators, 22 analytical tools and 46 graphical objects when you trade financial instruments in the trading platform.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12">
                                <h5 className="sami_medium_heading">
                                    Mobile trading and MetaTrader 5
                                </h5>
                                <p className="pe-lg-5">
                                    The trading terminal is not just for Windows, macOS and Linux powered desktops. Traders with busy schedules can also perform mobile trading on their iOS and Android smartphones and tablets. Equipped with all the integral trading tools, including trading orders, interactive charts and popular analytical tools, you can monitor your account and perform mobile trading in a single click.
                                </p>
                                <p className="pe-lg-5">
                                    The trading terminal is not just for Windows, macOS and Linux powered desktops. Traders with busy schedules can also perform mobile trading on their iOS and Android smartphones and tablets. Equipped with all the integral trading tools, including trading orders, interactive charts and popular analytical tools, you can monitor your account and perform mobile trading in a single click.
                                </p>

                                <button className="btn btn_black">Downloade MT5</button>
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <img src="/assets/images/mt4/mt5.webp" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row align-items-strech g-0">
                            <div className="col-lg-12 text-center mb-5">
                                <h4 className="sami_medium_heading">
                                    What you can trade on MT5
                                </h4>
                                <p className="font_15">
                                    At tradsify, you can enjoy trading CFDs on more than 200 instruments, which include trading
                                    <br className="d-lg-block d-none" />
                                    forex currency pairs, metals, , stocks, indices and energies.
                                </p>
                            </div>


                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mt4_card_border">
                                    <h6 className="sub_small_heading">
                                        Forex
                                    </h6>
                                    <p className="font_15">
                                        There are more than 100 currency pairs available for CFD trading on MT5 in tradsify. We offer major currency pairs, including EURUSD, GBPUSD and USDJPY, and minor currency pairs. There is also a long list of exotic pairs available for you to trade CFDs on.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mt4_card_border">
                                    <h6 className="sub_small_heading">
                                        Metals
                                    </h6>
                                    <p className="font_15">
                                        On MT5 with tradsify, you can trade CFDs on metals in the form of currency pairs, which include XAUUSD, XAUEUR, XAUGBP and XAUAUD for gold and XAGUSD, XAGEUR, XAGGBP and XAGAUD for silver. You can also trade on platinum (XPT) and palladium (XPD) in currency pairs.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mt4_card_border">
                                    <h6 className="sub_small_heading">
                                        Energies
                                    </h6>
                                    <p className="font_15">
                                        Hedge your portfolio on MT5 with tradsify and trade CFDs on popular energies like brent crude oil (UKOIL), crude oil (USOIL) and natural gas (XNGUSD) with better-than-market conditions.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mt4_card_border mt4_card_border_top">
                                    <h6 className="sub_small_heading">
                                        Stocks
                                    </h6>
                                    <p className="font_15">
                                        Gain exposure to a large selection of stock CFDs while trading on MT5 with tradsify. Trade CFDs on stocks from different industries, such as technology (APPL, META), consumer discretionary (TSLA), consumer staples (KO) and others.
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mt4_card_border mt4_card_border_top">
                                    <h6 className="sub_small_heading">
                                        Indices
                                    </h6>
                                    <p className="font_15 ">
                                        Diversify your portfolio and trade CFDs on major stock indices from the US, UK, Germany, Japan, and China on MT5 with tradsify. Access popular global indices like the Dow Jones, NASDAQ, FTSE 100, and NIKKEI 225.
                                    </p>
                                </div>
                            </div>
{/* 
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="mt4_card_border mt4_card_border_top">
                                    <h6 className="sub_small_heading">
                                        Cryptocurrencies
                                    </h6>
                                    <p className="font_15 mb-0">
                                        You can trade the most popular cryptocurrency pairs on MetaTrader 5. These include CFDs on Bitcoin, Ethereum and Litecoin, with Bitcoin available in BTCUSD, BTCKRW, BTCJPY and more.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center mb-5">
                                <h5 className="sami_medium_heading">
                                    MetaTrader 5 (MT5) Download
                                </h5>
                                <p>
                                    Discover a superior trading platform in MetaTrader 5 (MT5) now.
                                </p>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="border-image hover_gradgent">
                                    <span className="h6 fw-bold mb-3 d-block">1 STEP</span>
                                    <h3 className="sub_small_heading  mb-2">
                                        Download MT5
                                    </h3>
                                    <p className="font_15 mb-0">
                                        Open a live account or demo account to get the tradsify MetaTrader 5 (MT5) software.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="border-image hover_gradgent">
                                    <span className="h6 fw-bold mb-3 d-block">2 STEP</span>
                                    <h3 className="sub_small_heading  mb-2">
                                        Run Setup
                                    </h3>
                                    <p className="font_15 mb-0">
                                        Open the tradsifysetup.exe file and select “Run”.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="border-image hover_gradgent">
                                    <span className="h6 fw-bold mb-3 d-block">3 STEP</span>
                                    <h3 className="sub_small_heading  mb-2">
                                        Complete Installation
                                    </h3>
                                    <p className="font_15 mb-0">
                                        Allow changes, agree to the Licence Agreement, and choose settings if needed.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="border-image hover_gradgent">
                                    <span className="h6 fw-bold mb-3 d-block">4 STEP</span>
                                    <h3 className="sub_small_heading  mb-2">
                                        Finish MT5 Installation
                                    </h3>
                                    <p className="font_15 mb-0">
                                        Click "Next" to proceed through the tradsify MT5 Setup. The installer will download the latest MT5 version. Once done, click "Finish".
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space tradsify_faq">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-4 mb-3">Frequently asked questions</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-4">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >

                                            1.  Can you trade CFD on MetaTrader 5 (MT5) platform?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body font_16">
                                            Yes, you can trade Contracts for Difference (CFDs) on MT5 from your home country, whether you are from India or other regions of the world. tradsify offers CFD trading for forex, indices, shares, commodities, ETFs and bonds. You can trade these CFDs on both MetaTrader 4 (MT4) and MetaTrader 5 (MT5) trading platforms.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-4">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2.  What can you trade on MetaTrader 5 (MT5)?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body font_16">
                                            You can trade CFDs for forex, indices, shares, commodities, ETFs and bonds on MetaTrader 5. This means access to highly traded forex pairs, leading indices, top companies listed on stock exchanges, and more.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-4">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3.   Is MetaTrader 5 (MT5) suitable for beginners?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body font_16">
                                            MetaTrader 5 can be a suitable trading platform for all types of traders, from beginner to expert. As a beginner, MT5 allows you to gain access to a variety of tools for your fundamental and technical analysis.
                                            <br />
                                            With the latest version of MT5, you can also tap on the support of forex robots, trading signals and the capability to hedge positions. The MetaTrader 5 (MT5) platform can be accessed anywhere, anytime, and is available on both mobile apps and web browsers.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4.   Can you use MetaTrader 5 (MT5) for stocks?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body font_16">
                                            Yes, you can use MetaTrader 5 to trade stocks via share CFDs on tradsify. Stocks that you can trade include Apple (AAPL), Tesla (TSLA), Microsoft (MSFT), Netflix (NFLX), Alphabet (GOOG) and more.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}