import { Link } from "react-router-dom";

export default function Header() {
  return (
    <nav class="navbar navbar-expand-lg navbar-dark bg-light nav_padding">
      <div class="container-fluid">
        <Link to="/" class="navbar-brand fw-bold">
          <img src="/assets/images/logo/Tradsify.svg" alt=""className="img-fluid" />
        </Link>
        <button
          class="navbar-toggler bg-black"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse justify-content-center bg-light mobile-sidebar"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav mb-2 mb-lg-0">
            <li class="nav-item">
              <Link class="nav-link" to="/">
                Home
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/about">
                About Us
              </Link>
            </li>
            <li class="nav-item dropdown">
              <Link
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Platform
              </Link>
              <ul
                class="dropdown-menu bg-light mt-lg-3"
                aria-labelledby="navbarDropdown"
              >
                {/* <li class="dropdown-list d-flex align-items-center">
                  <Link to="/metatrader-4" class="nav-link">
                    metatrader-4
                  </Link>
                </li> */}
                <li class="dropdown-list d-flex align-items-center">
                  <Link to="/metatrader-5" class="nav-link">
                    metatrader-5
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li class="nav-item">
              <Link to="/guide" class="nav-link">
                Guide
              </Link>
            </li> */}
            <li class="nav-item">
              <Link to="/contact" class="nav-link">
                Contact
              </Link>
            </li>
          </ul>
          <div class="d-lg-none ps-4">
          <Link to="/login">
            <button class="btn btn_black me-2">Sign In</button>
          </Link>
        </div>
        </div>
        <div class="d-lg-flex d-none">
          <Link to="/login">
            <button class="btn btn_black me-2">Sign In</button>
          </Link>
        </div>
      </div>
    </nav>
  );
}
