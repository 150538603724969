import React from "react";
export default function MtFaq({title,faq}) {
  return (
        <section className="section_space tradsify_faq">
          <div className="container">
            <div className="text-center">
              <h2 className="sub_heading mb-xl-4 mb-3">{title}</h2>
              <div className="accordion" id="accordionExample">
                {faq.map((el, index) => (
                  <div className="accordion-item border-0 mb-4" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button collapsed font_20"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse${index}`}
                      >
                        {el.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body font_16">{el.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      );
    }
    
