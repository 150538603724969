import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import ScrollToTop from "../component/ScrollToTop";

export default function PrivacyPolicy() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space aml_bg ">
                    <div class="container">
                        <div class="row align-items-center text-center justify-content-center">
                            <div className="col-12">
                                <h2 class="main_heading">
                                    <span>Privacy Policy</span>
                                </h2>
                                <h4 className="font_18">Protecting your privacy and personal and financial data is one of our top priorities at tradsify.</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div class="container">
                        <div>
                            <p className="font_15 mb-5">
                                This Privacy Policy relates to your use of our website(s), tradsify Trade: Online Trading and application (the “app”) and to information collected by us in relation to your opening of and of your Account and the services provided by tradsify.
                            </p>

                            <h3 className="sub_para_title fw-bold mb-3">
                                Introduction
                            </h3>

                            <p className="font_15 mb-2">
                                Tradesify TRADE LTD. (hereafter “the Company”, “We”, “Us”) take your privacy very seriously. We understand the importance of maintaining the confidentiality and privacy of your personal data and information. By entrusting us with your personal data and information, we would like to assure you of our commitment to keep such information private. We have taken measurable steps to protect the confidentiality, security, and integrity of your personal data information.
                            </p>


                            <p className="font_15 mb-2">
                                Please read this privacy policy carefully as it contains important information on who we are and how and why we collect, store, use and share your personal information. By providing us with your personal information and using our services, you are accepting and consenting to the practices described in this policy. It also explains your rights in relation to your personal information and how to contact us or the supervisory authority in the event you have a complaint.
                            </p>
                            <p className="font_15 mb-5">
                                We collect, use and process certain personal information about you. When we do so we are subject to the Protection of Personal Information Act (hereafter “ POPIA”) and we are responsible as ‘Controller’ of that personal information for the purposes of the Regulation as well as Nauru Law providing for the protection of natural persons with regard to the processing of personal data and for the free movement of such data.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-3">
                                Definitions
                            </h3>

                            <p className="font_15 mb-2">
                                Data Protection Officer (“DPO”) - support@tradsify.com
                            </p>

                            <p className="font_15 mb-2">
                                Personal information -Any information relating to an identified or identifiable individual.
                            </p>

                            <p className="font_15 mb-5">
                                Special categories of personal information -Personal information revealing racial or ethnic origin, political opinions, religious beliefs, philosophical beliefs, or trade union membership; Genetic and biometric data; Data concerning health, sex life or sexual orientation.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-3">
                                Information we collect about you
                            </h3>

                            <p className="font_15 mb-2">
                                <b> Information you give us:</b>  This is information about you that you give us by filling our client forms/ agreements, or by corresponding with us by phone, e-mail, in person or otherwise. It includes information you provide when you choose to use our services and/or become an affiliate to our company, participate in any company event and when you send any information to us under any capacity. The information you give us may include your name, address, e-mail address and phone number, financial information etc. When you visit our website(s) we collect information about your visit, including the full Uniform Resource Locators (URL).
                            </p>

                            <p className="font_15 mb-5">
                                <b>Information we collect/receive from other sources:</b>This is information we receive about you if you use any of the websites we operate or the services we provide and/or from other affiliates and/or professionals. We are working closely with third parties (including, for example, business partners, brokers, payment service providers, analytics providers, search information providers, credit reference agencies). We will notify you when we receive information about you from them and the purposes for which we intend to use that information.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-3">
                                How we use your data
                            </h3>
                            <p className="font_15 mb-2">
                                To carry out our obligations arising from any agreements and/or contracts between you and us and to provide you with the information, products, and services that you request from us.
                            </p>

                            <p className="font_15 mb-2">
                                For our legitimate interests or those of a third party, for example, to prevent and detect fraud/unauthorized access to our systems that can be damaging to us and you and/or to be as efficient as we can so we can deliver the best service to you at the best price and/or conducting credit reference checks via external credit reference agencies to ensure our customers are likely to be able to pay for our products and services.
                            </p>
                            <p className="font_15 mb-2">
                                To comply with our legal and regulatory obligations, for example, conducting checks to identify our customers and verify their identity as required by the Convention for the Suppression of the Financing of Terrorism Act 1996 and supplemented by the Prevention of Organized Crime Act (no. 121 of 1998). The FIC Amendment Act (No. 11 of 2008) was issued in August 2008 which took effect in 2010 and clarified the roles and responsibilities of supervisory bodies. The Money Laundering and Terrorist Financing Control regulations were published in 2002 and have since been amended on various occasions; they create a comprehensive legal framework for the combating of money laundering and terrorist financing.
                            </p>

                            <p className="font_15 mb-2">
                                To provide you with information about other services we offer that are similar to those that you have already used or enquired about;
                            </p>

                            <p className="font_15 mb-2">
                                To notify you about changes to our services.
                            </p>
                            <p className="font_15 mb-5">
                                Client feedback is pivotal to our commitment to professional excellence. Therefore, we may request you to provide your feedback on any of our services or products you have received through reputable online customer review websites. Your feedback may be shared for promotional and marketing purposes.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-3">
                                Disclosure of your information
                            </h3>

                            <p className="font_15 mb-2">
                                You agree that we have the right to share your information with:
                            </p>
                            <p className="font_15 mb-2">
                                Any member of our group and its subsidiaries.
                            </p>
                            <p className="font_15 mb-2">
                                <b>Selected third parties we use to help deliver our products and/or services to you including: </b> Business partners, brokers, vendors, insurers, payment service providers for the performance of any contract we enter into with them or you.
                            </p>
                            <p className="font_15 mb-2">
                                Analytics and search engine providers that assist us in the improvement and optimization of our site, Credit reference agencies and/or banking and financial institutions and/or auditors for, among others, the purpose of assessing your credit score (where this is a condition of us entering into a contract with you).
                            </p>
                            <p className="font_15 mb-2">
                                Any Nauru Law enforcement agencies and regulatory bodies for the purposes of complying with local and international regulations against money-laundering, fraud and the general prevention of crime and illegal activities.
                            </p>
                            <p className="font_15 mb-2">
                                We will disclose your personal information to third parties:
                            </p>
                            <p className="font_15 mb-2">
                                In the event that this is necessary for the delivery of our products and/or services to you;
                            </p>
                            <p className="font_15 mb-2">
                                If Tradesify Trade Ltd or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.
                            </p>
                            <p className="font_15 mb-2">
                                If we are under a duty to disclose or share your personal data to comply with any legal and/or regulatory obligation, or to enforce or apply our terms of use and other agreements, or to protect the rights, property, or safety of Tradesify Trade Ltd, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection, anti-money laundering and credit risk reduction.
                            </p>
                            <p className="font_15 mb-2">
                                We only allow the third parties we work with to process your personal information if we are satisfied, they take appropriate technical and organizational measures to protect your personal information.
                            </p>
                            <p className="font_15 mb-5">
                                We also impose contractual obligations on our selected third parties to ensure they can only use your personal information to provide services to us and to you.
                            </p>


                            <h3 className="sub_para_title fw-bold mb-3">
                                Where we store your personal data
                            </h3>
                            <p className="font_15 mb-2">
                                All information you provide to us is stored on secure servers. Where we may have given you (or where you have chosen) a password that enables you to access certain parts of our site and/or use any other of our services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                            </p>
                            <p className="font_15 mb-5">
                                Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to us; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-3">
                                Duration that your personal information will be kept
                            </h3>
                            <p className="font_15 mb-2">
                                We will keep your personal information while you have an account with us or while we are providing services and/or products to you or while we are in a contractual relationship with you. Thereafter, we will keep your personal information for as long as is necessary:
                            </p>
                            <p className="font_15 mb-2">
                                to respond to any questions, complaints or claims made by you or on your behalf
                            </p>
                            <p className="font_15 mb-2">
                                to show that we treated you fairly
                            </p>
                            <p className="font_15 mb-2">
                                to keep records required by law, regulations, and regulatory authorities.
                            </p>
                            <p className="font_15 mb-5">
                                We will not retain your personal information for longer than necessary for the purposes set out in this policy. Different retention periods apply to different types of personal information. When it is no longer necessary to retain your personal information, we will delete or anonymize it.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-md-3">
                                Your rights
                            </h3>

                            <p className="font_15 mb-2">
                                You have the following rights, which you can exercise:
                            </p>
                            <p className="font_15 mb-2">
                                Access - The right to be provided with a copy of your personal information.
                            </p>
                            <p className="font_15 mb-2">
                                Rectification - The right to require us to correct any mistakes in your personal information.
                            </p>
                            <p className="font_15 mb-2">
                                To be forgotten - The right to require us to delete your personal information—in certain situations.
                            </p>
                            <p className="font_15 mb-2">
                                Restriction of processing - The right to require us to restrict processing of your personal information— in certain circumstances, for example, if you contest the accuracy of the data.
                            </p>
                            <p className="font_15 mb-2">
                                Data portability -The right to receive the personal information you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party—in certain situations.
                            </p>
                            <p className="font_15 mb-2">
                                <b>To object - The right to object: </b>at any time to your personal information being processed and in certain other situations to our continued processing of your personal information, for example, processing carried out for the purpose of our legitimate interests.
                            </p>
                            <p className="font_15 mb-2">
                                Not to be subject to automated individual decision-making the right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you.
                            </p>
                            <p className="font_15 mb-2">
                                Moreover, if the data subject has provided his/her consent to any of the processing of his/her personal data, he/she has the right to withdraw his/her consent to that processing at any time, where relevant. He/ she must contact the DPO if he/she wishes to do so.
                            </p>
                            <p className="font_15 mb-2">
                                We aim to comply with requests of data subjects as quickly as possible and will ensure that we comply with legislation unless exceptions apply. In such cases, the reasons for any delay will be explained in writing to the data subject making the request.
                            </p>
                            <p className="font_15 mb-2">
                                In processing a request, the identity of the data subject needs to be verified before information will be released. No personal data will be disclosed to a third party without the written consent of the data subject.
                            </p>
                            <p className="font_15 mb-2">
                                We reserve the right to refuse repeated/vexatious requests.
                            </p>
                            <p className="font_15 mb-5">
                                For further information on each of those rights, including the circumstances in which they apply, please contact our DPO.
                            </p>



                            <h3 className="sub_para_title fw-bold mb-3">
                                Keeping your personal information secure
                            </h3>
                            <p className="font_15 mb-2">
                                We have appropriate technical and organizational security measures to prevent personal information from being accidentally lost, used, or accessed unlawfully. We limit access to your personal information to those who have a genuine business need to access it. Those processing your information will do so only in an authorized manner and are subject to a duty of confidentiality.
                            </p>

                            <p className="font_15 mb-5">
                                We also have procedures in place to deal with any suspected data security breaches. We will notify you and the relevant supervisory authority of a suspected data security breach when we are legally required to do so.
                            </p>


                            <h3 className="sub_para_title fw-bold mb-3">
                                How to complain
                            </h3>
                            <p className="font_15 mb-2">
                                We hope that we and our DPO can resolve any concern or complain you may have about our use of your information.
                            </p>
                            <p className="font_15 mb-5">
                                The POPIA also gives you the right to lodge a complaint with the Commissioner. The supervisory authority in Nauru is the Data Protection Office.
                            </p>

                            <h3 className="sub_para_title fw-bold mb-3">
                                Changes to this privacy policy
                            </h3>
                            <p className="font_15 mb-2">
                                This privacy policy was last updated Oct 2024. This Policy is subject to change without notice. For this reason, you are advised to look for updates from time to time.
                            </p>
                            <p className="font_15 mb-5">
                                We are constantly reviewing how we process and protect data. Therefore, changes to our privacy policy may occur at any time. We will endeavor to publish any changes. You are advised to check our website www.tradsify.com regularly for any amendments.
                            </p>


                            <h3 className="sub_para_title fw-bold mb-3">
                                How to contact us
                            </h3>

                            <p className="font_15 mb-2">
                                Please contact our DPO by post, email, or telephone if you have any questions about this privacy policy or the information, we hold about you.
                            </p>
                            <p className="font_15 mb-2">
                                Our DPO's contact details
                            </p>
                            <p className="font_15 mb-2">
                                Data Protection Officer Tradesify TRADE LTD  Ground Floor, The Sotheby Building, Rodney Village, Rodney Bay, Gros-Islet, Saint Lucia.
                            </p>

                            <p className="font_15 mb-4">
                                Email : <Link to={"mailto:support@tradsify.com"}>
                                    support@tradsify.com
                                </Link>
                            </p>
                        </div>
                    </div>
                </section >
                <Footer />
            </ScrollToTop>
        </>
    )
}